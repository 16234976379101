import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { SettingAPI } from "../../../axios/api";
import {
  useAbortedEffect,
  useSearch,
  useChipForm,
  useUploadXlsx,
} from "../../../components/hooks";
import moment from "moment";

import ModuleBreadCrumb from "../../../components/breadCrumb";
import { setPageSetting } from "../../../service/common";
import { system_dropdown } from "../../../service/system";
import { option_enabled } from "../../../service/option";

import ExampleXlsx from "../../../files/machineType.xlsx";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

export default function BackSettingMachineType() {
  const route_navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbItem = [
    { key: 1, label: "設定管理" },
    { key: 2, label: "機種管理" },
  ];
  const [reload, setReload] = useState({ list: false });
  const [apiData, setApiData] = useState([]);
  const [optionData, setOptionData] = useState(null);
  // 篩選條件
  const defaultFilter = {
      page: -1,
      type: null,
    },
    pagePath = "/setting/machineType";
  const {
    filterData,
    setFilterData,
    search,
    setSearch,
    changeFilter,
    renderSearchWrap,
  } = useSearch({
    defaultFilter,
    pagePath: [pagePath],
    reloadAction: () => setReload((state) => ({ ...state, list: true })),
  });
  const { setChipData, addChip, renderChipBlock } = useChipForm({
    keyObj: {
      id: {
        key: "id",
        label: "",
        isForm: false,
      },
      // type: {
      //   key: "type",
      //   label: "機型",
      //   isForm: true,
      //   ui: "text",
      //   isRequired: true,
      // },
      label: {
        key: "model",
        label: "機種",
        isForm: true,
        ui: "text",
        isRequired: true,
      },
    },
    action: {
      insert: (data) =>
        SettingAPI.setModelTypeData({
          data,
        }),
      delete: (data) =>
        SettingAPI.deleteModelTypeData({
          data: { id: data.id },
        }),
      reload: () => setReload((state) => ({ ...state, list: true })),
    },
  });
  const [uploadData, setUploadData] = useState(null);
  const { uploadColumns, renderUpload } = useUploadXlsx({
    columns: [
      // {
      //   key: "type",
      //   label: "機型",
      //   isRequired: true,
      // },
      {
        key: "model",
        label: "機種",
        isRequired: true,
        matchWord: "-",
      },
    ],
    action: {
      format: (data) => formatAction(data),
      upload: (data) =>
        SettingAPI.importModelTypeData({
          data: data.data,
        }),
      reload: () => setReload((state) => ({ ...state, list: true })),
    },
    example: {
      name: "machineType",
      file: ExampleXlsx,
    },
  });

  // useAbortedEffect((signal) => {
  //   dispatch(setProgress(true));

  //   SettingAPI.getModelTypesDistinct({
  //     options: { signal },
  //   }).then((result) => {
  //     let { Response, data, message } = result;

  //     if (Response === 1) {
  //       setOptionData((state) => ({
  //         ...state,
  //         typeList: data.map((item) => ({ label: item, value: item })),
  //       }));
  //     } else {
  //       dispatch(
  //         setToast({
  //           severity: "error",
  //           summary: message,
  //           detail: "",
  //         })
  //       );
  //       dispatch(setProgress(false));
  //     }
  //   });
  // }, []);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
      };

      SettingAPI.getModelTypeDataList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;

        if (Response === 1) {
          data.sort(function (pre, next) {
            return pre.model.localeCompare(next.model);
          });
          setChipData((state) => ({
            ...state,
            data,
          }));
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [reload]
  );

  const formatAction = (data) => {
    let isValid = true,
      message = "",
      startIndex = 0;
    let requireColumns = [],
      notMatchColumns = {};

    data.splice(0, startIndex);
    data = data.map((item) => {
      let details = Object.values(item);

      return {
        ...uploadColumns.reduce((pre, cur, cIdx) => {
          let value = details[cIdx]?.trim();

          if (
            (value === "" || value === null || value === undefined) &&
            cur.isRequired &&
            !requireColumns.includes(cur.label)
          ) {
            isValid = false;
            requireColumns.push(cur.label);
          } else if (cur.matchWord && value.indexOf(cur.matchWord) === -1) {
            isValid = false;
            if (!notMatchColumns[cur.label])
              notMatchColumns[cur.label] = {
                message: `${cur.label}需包含『${cur.matchWord}』`,
                data: [],
              };
            notMatchColumns[cur.label].data.push(cIdx + 2);
          }

          return {
            ...pre,
            [cur.key]:
              cur.type === "date"
                ? moment(details[cIdx]).format("YYYY-MM-DD HH:mm")
                : details[cIdx],
          };
        }, {}),
      };
    });

    data = data.reduce(
      (pre, cur) => ({
        ...pre,
        [cur.model]: cur,
      }),
      {}
    );
    data = Object.values(data);

    if (requireColumns.length > 0)
      message += `『${requireColumns.join("、")}』為必填欄位`;

    notMatchColumns = Object.values(notMatchColumns);
    if (notMatchColumns.length > 0) {
      notMatchColumns.forEach((item) => {
        message += `${requireColumns.length > 0 ? "\n" : ""}${item.message}`;
      });
    }

    return {
      isValid,
      message,
      data,
    };
  };

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <h2 className="my-3 ml-1">機種管理</h2>

      <div className="border-round-lg bg-white p-3">
        {renderSearchWrap({
          isShow: false,
          template: (
            <>
              {/* <div className="col-12 md:col-4 xl:col-3 flex align-items-start flex-column mt-2 md:mt-0">
                <label
                  htmlFor="type"
                  className="w-4rem white-space-nowrap text-left mb-1"
                >
                  機型
                </label>
                <Dropdown
                  name="type"
                  className="w-full"
                  value={filterData.type}
                  onChange={(e) => changeFilter(e)}
                  options={optionData?.typeList ?? []}
                  emptyFilterMessage={system_dropdown.emptyFilter}
                  showClear
                />
              </div> */}
              <div
                className={classNames(
                  "col-12 flex align-items-end mt-2 md:mt-0"
                  // md:col-8 xl:col-9
                )}
              >
                {/* <Button
                  className="p-button-info p-button-sm w-auto ml-auto md:ml-0 md:mt-0 px-3"
                  type="button"
                  icon="pi pi-search"
                  label="查詢"
                  onClick={() =>
                    setSearch({
                      ...filterData,
                    })
                  }
                /> */}

                <div className="ml-auto">
                  {renderUpload()}
                  <Button
                    className="p-button-main p-button-sm w-auto ml-2 px-3"
                    type="button"
                    label="新增"
                    onClick={() => addChip()}
                  />
                </div>
              </div>
            </>
          ),
        })}

        <div className="mt-2">{renderChipBlock()}</div>
      </div>
    </>
  );
}
